<template>
    <q-page>
        <router-view/>
    </q-page>
</template>

<script>

export default {
    name: "Estrazioni",
    data() {
        return {
        }
    },
}
</script>
